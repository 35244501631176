<template>
  <section class="basket-form" id="checkout">

    <v-container v-if="isCheckingOut" key="2">
      <v-form @submit.prevent="submit">

        <basket-form-postcards class="mt-8" :form="form"/>
        <!--<divider class="mt-10"/>-->

        <basket-form-destinations class="mt-14" :form="form"/>
        <template v-if="destination">
          <basket-form-delivery-types v-if="withDeliveryTypes"
                                      @express-delivery-change="handleExpressDeliveryChange"
                                      class="mt-14"
                                      :is-express-delivery-unavailable="isExpressDeliveryUnavailable"
                                      :form="form"/>

          <basket-form-delivery-courier v-if="isDeliveryTypeCourier"
                                        class="mt-14"
                                        :form="form"/>
          <basket-form-delivery-self v-else-if="isDeliveryTypeSelf" class="mt-14" :form="form"/>

        </template>

        <template v-if="isDeliverySpecified">
          <basket-form-delivery-date v-if="withDeliveryTypes && form.deliveryTypeId !== 3" class="mt-14"
                                     :form="form"/>
          <basket-form-contacts class="mt-14" :form="form "/>
          <basket-form-comments class="mt-14" :form="form "/>
          <basket-form-discount-card class="mt-14" :form="form"
                                     @onSuccessfulCardCheck="onSuccessfulCardCheck"
                                     @onUnsuccessfulCardCheck="onUnsuccessfulCardCheck"
                                     @onCancel="onDiscountCardCancel"/>

          <!--		  					<basket-form-coupon class="mt-14" :form="form"/>-->

          <divider class="mt-14"/>

          <basket-form-cost class="mt-14" :form="form"/>

          <basket-form-payment class="mt-14" :form="form"/>

<!--          <basket-form-subscription class="mt-14" :form="form"/>-->
          <basket-form-agreement class="mt-14" :form="form"/>
          <btn-primary type="submit"
                       class="mt-8 d-block large mx-auto"
                       :disabled="!!validationError"
                       :loading="loading"
                       label="Продолжить"/>

          <p v-if="validationError"
             class="text-center mt-8">{{ validationError }}</p>

        </template>
      </v-form>
    </v-container>
    <v-container v-else key="2" class="text-center">
      <btn-primary :loading="loading" large @click="onCheckout">Оформить заказ</btn-primary>
    </v-container>

    <v-dialog v-model="isErrorDialog" width="500">
      <v-card>
        <v-card-title class="justify-center">
          {{ this.isErrorDeliveryTime ? 'Выберите другую дату' : 'Проверьте форму' }}
        </v-card-title>

        <v-card-text class="justify-center text-center size-sm-md">
          {{ error }}
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
              text
              @click="error = null; isErrorDialog = null;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isNotificationDialogShown" width="500">
      <v-card>
        <v-card-text class="size-sm-md py-5 px-6">
          <div v-html="notificationText"></div>
          <div v-if="notificationProducts && notificationProducts.length" class="basket-form__notification-form-products">
            <div v-for="(product) in notificationProducts" :key="product.ID">
              {{ capitalize(product.NAME.replace(/<[^>]*>/g, ' ')) }} {{ product.SUBNAME }}
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
              text
              @click="notificationText = null; isNotificationDialogShown = null;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import Divider from "@/components/divider";
import BtnPrimary from '@/components/btn-primary';
import BtnText from '@/components/btn-text';
import BasketFormCoupon from './basket-form-coupon';
import BasketFormPostcards from './basket-form-postcards';
import BasketFormDestinations from './basket-form-destinations';
import BasketFormDeliveryTypes from './basket-form-delivery-types';
import BasketFormDeliveryCourier from './basket-form-delivery-courier';
import BasketFormDeliverySelf from './basket-form-delivery-self';
import BasketFormDeliveryDate from './basket-form-delivery-date';
import BasketFormContacts from './basket-form-contacts';
import BasketFormPayment from './basket-form-payment';
import BasketFormComments from './basket-form-comments';
import BasketFormCost from './basket-form-cost';
import BasketFormAgreement from './basket-form-agreement';
import {scrollTo} from '@/utils/utils';
import {geocodeYandex} from '@/utils/geo';
import {
  destinations,
  deliveryTypes,
  DELIVERY_TYPE_EXPRESS,
  DESTINATION_RUSSIA, DESTINATION_SPB, DESTINATION_MSK,
} from '@/store/delivery';
import vp from '@/mixins/vp';
import city from "@/mixins/city";

import Config from '@/config';
import BasketFormDiscountCard from "@/views/Basket/basket-form-discount-card.vue";
import BasketFormSubscription from "@/views/Basket/basket-form-subscription.vue";
import BasketNotification from "@/views/Basket/basket-notification.vue";
import config from "@/config";
import {capitalize} from "../../utils/string";

export default {
  name: "basket-form",
  components: {
    BasketNotification,
    BasketFormSubscription,
    BasketFormDiscountCard,
    BtnPrimary,
    BtnText,
    Divider,
    BasketFormCoupon,
    BasketFormPostcards,
    BasketFormDestinations,
    BasketFormDeliveryTypes,
    BasketFormDeliveryCourier,
    BasketFormDeliverySelf,
    BasketFormDeliveryDate,
    BasketFormContacts,
    BasketFormPayment,
    BasketFormComments,
    BasketFormCost,
    BasketFormAgreement,
  },
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  mixins: [vp, city],
  data() {
    return {
      form: {
        destinationId: null,
        deliveryId: null,	// битрикс delivery.ID

        // искусственные установки для ФЕ
        deliveryTypeId: null,	// курьер, экспресс, самовывоз
        deliveryZoneId: null,	// зона доставки (курьер|экспресс)
        deliveryPlaceId: null,	// точка самовывоза
        deliveryCoords: null,	// точка на карте (в случае доставки курьером)
        deliveryCost: null,	// стоимость доставки (может быть null) - для сопоставления на БЕ

        paymentSystemId: null,

        DATE_DELIVERY: null,
        TIME: null,

        //COUNTRY: 'Россия',
        //ZIP: '',
        CITY: '',
        //STATE: '',
        STREET: '',
        BUILDING: '',
        APT: '',

        NAME: '',
        EMAIL: '',
        PHONE: '',

        COMMENTS: '',

        DISCOUNT_CARD: '',

        POSTCARD_ID: 0,
        POSTCARD_PRICE: 0,
        POSTCARD_TEXT: '',

        CARD_NUMBER: '',

        IS_SUBSCRIBE: 'N',

        discountBasket: null,
        couponDiscount: 0,
        hasAccepted: false,
      },
      isCardChecked: false,
      loading: false,
      isErrorDialog: false,
      isSuccessDialog: false,
      error: null,
      isCheckingOut: true,
      isExpressDelivery: false,
      isCourierDeliveryAvailable: false,
      DELIVERY_HOUR_LIMIT: Config.DELIVERY_HOUR_LIMIT,
      isErrorDeliveryTime: false,

      isNotificationDialogShown: null,
      notificationText: null,
      notificationProducts: [],

      isMinWeightReached: null,
    }
  },
  watch: {
    isCakeNotificationShown(isShown) {
      if (isShown) {
        this.notificationText = this.cakeNotificationText;
        this.notificationProducts = [];
        this.isNotificationDialogShown = true;
      }
    }
  },
  computed: {
    sections() {
      return this.$store.state.common.sections;
    },
    isUnavailableProductsExists() {
      return this.getUnavailableBasketItemsCount(this.$store.state.session?.basket) > 0;
    },
    validationError() {
      if (this.isCakeNotificationShown) return this.cakeErrorText;
      if (this.isUnavailableProductsExists) return "Пожалуйста, удалите из корзины недоступные товары.";
      if (!this.form.destinationId) return "Пожалуйста, выберите пункт назначения.";
      //if ( !this.form.delivery ) return "Пожалуйста, выберите пункт назначения.";
      if (this.isDeliveryTypeCourier) {
        if (!this.form.CITY) return "Пожалуйста, укажите город адреса доставки.";
        if (!this.form.STREET) return "Пожалуйста, укажите улицу адреса доставки.";
        if (this.withDeliveryTypes && this.form.deliveryTypeId !== 3) {
          if (!this.form.DATE_DELIVERY) return "Пожалуйста, выберите дату доставки.";
          if (!this.form.TIME) return "Пожалуйста, укажите временной интервал доставки.";
        }
      } else if (this.isDeliveryTypeSelf) {
        if (!this.form.STREET) return "Пожалуйста, выберите пункт самовывоза.";
        if (!this.form.deliveryPlaceId) return "Пожалуйста, выберите пункт самовывоза.";
        if (!this.form.DATE_DELIVERY) return "Пожалуйста, выберите дату получения.";
      }

      if (!this.form.NAME) return "Пожалуйста, укажите контактное имя получателя.";
      if (!this.form.EMAIL) return "Пожалуйста, укажите контактный емейл получателя.";
      if (!this.form.PHONE) return "Пожалуйста, укажите контактный телефон получателя.";
      if (this.form.DISCOUNT_CARD && !this.isCardChecked) return "Пожалуйста, нажмите Проверить в поле ввода номера скидочной карты.";
      if (!this.form.paymentSystemId) return "Пожалуйста, укажите вариант оплаты.";
      if (!this.form.hasAccepted) return "Пожалуйста, ознакомьтесь и примите наши Общие условия продажи.";
      return null;
    },
    isExpressDeliveryUnavailable() {
      return this.items.filter(item => item.product.IS_EXPRESS_UNAVAILABLE)?.length > 0;
    },
    destination() {
      console.log('dest: ', destinations.find(el => el.id === this.form.destinationId), this.form.destinationId);
      return destinations.find(el => el.id === this.form.destinationId);
    },
    deliveryType() {
      return deliveryTypes.find(el => el.id === this.form.deliveryTypeId);
    },
    withDeliveryTypes() {
      return this.destination && this.destination.deliveryTypeIds?.length;
    },
    isDeliveryTypeCourier() {
      return (this.deliveryType && !this.isDeliveryTypeSelf) || !this.withDeliveryTypes;
    },
    isDeliveryTypeSelf() {
      return this.withDeliveryTypes && this.deliveryType?.isSelf;
    },
    isDeliverySpecified() {
      return this.destination &&
          ((this.isDeliveryTypeCourier && this.form.deliveryZoneId)
              || (this.isDeliveryTypeSelf && this.form.deliveryPlaceId)
              || !this.withDeliveryTypes);
    },
    cakeNotificationText() {
      return this.$store.state.page.BasketController?.settings?.CAKE_NOTIFICATION?.DETAIL_TEXT;
    },
    cakeErrorText() {
      return this.$store.state.page.BasketController?.settings?.CAKE_NOTIFICATION?.PREVIEW_TEXT;
    },
    isCakeNotificationShown() {
      return this.items.filter(item => item.product.IBLOCK_SECTION_ID === '52')?.length > 0
          && ((this.form.deliveryTypeId !== null && this.form.deliveryTypeId !== 1 && this.form.deliveryTypeId !== 4) ||
          this.form.destinationId === 3);
    },
    cdekMinWeight() {
      return Number(this.$store.state.page.BasketController?.settings?.CDEK_NOTIFICATION?.VALUE);
    },
    cdekNotificationText() {
      return this.$store.state.page.BasketController?.settings?.CDEK_NOTIFICATION?.DETAIL_TEXT;
    },
    unavailableGoodsInDestinationNotificationText() {
      return this.$store.state.page.BasketController?.settings?.UNAVAILABLE_GOODS_IN_REGION_NOTIFICATION?.DETAIL_TEXT;
    },
  },
  methods: {
    capitalize,
    onSuccessfulCardCheck() {
      this.isCardChecked = true;
    },
    onUnsuccessfulCardCheck(errorMessage) {
      this.error = errorMessage;
      this.isErrorDialog = true;
    },
    onDiscountCardCancel() {
      this.isCardChecked = false;
    },
    handleExpressDeliveryChange(isExpressDelivery) {
      this.isExpressDelivery = isExpressDelivery;
    },
    onCheckout() {
      this.isCheckingOut = true;
      setTimeout(() => {
        scrollTo('checkout', -120);
      }, 300);
    },
    checkDeliveryAvailability() {
      const now = new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"});
      const currentHour = new Date(now).getHours();
      if (currentHour >= 10 && currentHour < 16) {
        this.isExpressDelivery = true;
      } else {
        this.isExpressDelivery = false;
      }
    },
    checkCourierDeliveryAvailability() {
      const now = new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"});
      const currentHour = new Date(now).getHours();

      if (currentHour < this.DELIVERY_HOUR_LIMIT) {
        this.isCourierDeliveryAvailable = true;
      } else {
        const today = new Date().toLocaleString("ru-RU", {timeZone: "Europe/Moscow"}).split(",")[0];
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowFormatted = tomorrow.toLocaleString("ru-RU", {timeZone: "Europe/Moscow"}).split(",")[0];
        const deliveryDate = this.form.DATE_DELIVERY;

        // Если дата доставки совпадает с сегодняшней или завтрашней датой, курьерская доставка недоступна
        if (deliveryDate === today || deliveryDate === tomorrowFormatted) {
          this.isCourierDeliveryAvailable = false;
        } else {
          this.isCourierDeliveryAvailable = true;
        }
      }
    },
    checkMinProductsWeight() {
      const productsWeight = this.items.reduce((weight, item) => {
        const productWeight = Number(item.product.WEIGHT);
        const productAmount = item.AMOUNT;

        if (!productWeight) console.warn('Для товара ' + item.product.NAME + ' не указан вес.');

        return weight + (productWeight * productAmount);
      }, 0);

      this.isMinWeightReached = productsWeight >= this.cdekMinWeight;
    },
    getBasketProductAvailability(product, section) {
      if (!product || !section) return true;
      if (this.destination.id === DESTINATION_SPB) {
        if (product.NO_SPB || Number(section.UF_NO_SPB)) return false;
      } else if (this.destination.id === DESTINATION_MSK) {
        if (product.NO_MSK || Number(section.UF_NO_MSK)) return false;
      } else if (this.destination.id === DESTINATION_RUSSIA) {
        if (product.NO_MSK || Number(section.UF_NO_MSK) || product.NO_SPB || Number(section.UF_NO_SPB) || product.NO_CDEK) return false;
      }
      return true;
    },
    checkBasketProductsAvailability() {
      if (!this.items?.length) return false;

      let hasUnavailableProducts = false;
      let unavailableItems = [];

      this.items.forEach((product) => {
        product = product.product;
        const section = this.sections?.find(section => section.ID === product.IBLOCK_SECTION_ID);
        const isProductAvailable = this.getBasketProductAvailability(product, section);

        if (!isProductAvailable) {
          hasUnavailableProducts = true;
          unavailableItems.push(product);
        }
      });

      return {
        hasUnavailableProducts,
        unavailableItems,
      }
    },
    submit() {
      const vals = [
        {
          re: /^.{3,}$/,
          cond: this.form.destination === 2 || this.form.destination === 3,
          value: this.form.PERSONAL_ZIP,
          msg: "Пожалуйста, укажите почтовый индекс"
        },
        {
          re: /^.{2,}$/,
          cond: this.form.destination === 3,
          value: this.form.PERSONAL_COUNTRY,
          msg: "Пожалуйста, укажите страну"
        },
        {
          re: /^.{2,}$/,
          cond: this.form.destination === 2 || this.form.destination === 3,
          value: this.form.PERSONAL_CITY,
          msg: "Пожалуйста, укажите город"
        },
        {
          re: /^.{2,}$/,
          value: this.form.PERSONAL_STREET,
          msg: "Пожалуйста, укажите адрес доставки"
        },
        {
          re: /^.+$/,
          value: this.form.PERSONAL_MAILBOX,
          msg: "Пожалуйста, укажите номер дома"
        },
        {
          re: /^.+$/,
          value: this.form.PERSONAL_NOTES,
          msg: "Пожалуйста, укажите номер квартиры/офиса"
        },
        {
          re: /^.{2,}$/,
          value: this.form.NAME,
          msg: "Пожалуйста, укажите Ваше полное имя"
        },
        {
          re: /^.+?@.+?\..+$/,
          value: this.form.EMAIL,
          msg: "Пожалуйста, укажите Ваш адрес электронной почты"
        },
        /*{
          re: /^\d{10,24}$/,
          value: this.form.PHONE.replace(/\D+/g, ""),
          msg: "Пожалуйста, укажите Ваш номер телефона"
        },
        {
          re: /^\d{10,24}$/,
          value: this.form.PHONE.replace(/\D+/g, ""),
          msg: "Пожалуйста, укажите Ваш номер телефона"
        }*/
      ];
      this.isErrorDeliveryTime = false;
      this.isErrorDialog = false;
      vals.forEach(el => {
        if (this.isErrorDialog) return;
        if (typeof el.cond !== "undefined" && !el.cond) return;
        if (!el.re.test(el.value)) {
          this.isErrorDialog = true;
          return (this.error = el.msg);
        }
      });
      if (this.isErrorDialog) return;

      this.loading = true;
      this.error = null;

      // Проверяем, все ли товары доступны в выбранном регионе
      const productsAvailability = this.checkBasketProductsAvailability();
      if (productsAvailability.hasUnavailableProducts) {
        this.notificationText = this.unavailableGoodsInDestinationNotificationText;
        this.notificationProducts = productsAvailability.unavailableItems;
        this.isNotificationDialogShown = true;
        this.loading = false;
        return;
      }

      // Если выбрана доставка в другой город проверяем минимальный вес заказа
      if (this.form.destinationId === DESTINATION_RUSSIA) {
        this.checkMinProductsWeight();

        if (!this.isMinWeightReached) {
          this.notificationText = this.cdekNotificationText;
          this.notificationProducts = [];
          this.isNotificationDialogShown = true;
          this.loading = false;
          return;
        }
      }
      //Проверяем, доступна ли сейчас Экспресс-доставка
      this.checkDeliveryAvailability();
      if (!this.isExpressDelivery && this.form.deliveryTypeId === DELIVERY_TYPE_EXPRESS) {
        this.isErrorDeliveryTime = true;
        this.error = "Экспресс-доставка сейчас недоступна. Пожалуйста, выберите другой вариант доставки.";
        this.isErrorDialog = true;
        this.loading = false;
        return;
      }
      //Проверяем, доступна ли доставка на выбранную дату
      this.checkCourierDeliveryAvailability();
      if (!this.isCourierDeliveryAvailable) {
        this.isErrorDeliveryTime = true;
        this.error = "На выбранную дату доставка недоступна. Пожалуйста, выберите другой день.";
        this.isErrorDialog = true;
        this.loading = false;
        return;
      }

      this.$store.dispatch('post', {
        action: 'OrderController',
        params: this.form
      }).then((res) => {
        //this.isSuccessDialog = true;
        this.$router.push({name: 'order', params: {id: res?.page?.OrderController?.order?.ID}});
      }).catch((error) => {
        if (error.message === 'curier20') {
          this.isErrorDeliveryTime = true;
          this.error = "На выбранную дату доставка недоступна. Пожалуйста, выберите другой день.";
        } else if (error.message === 'payment') {
          this.isErrorDeliveryTime = true;
          this.error = "Оплата картой на сайте сейчас недоступна. Пожалуйста, выберите другой способ оплаты.";
        } else if (error.message === 'express') {
          this.isErrorDeliveryTime = true;
          this.error = "Экспресс-доставка сейчас недоступна. Пожалуйста, выберите другой вариант получения заказа.";
        } else if (error.message === 'other-city') {
          this.isErrorDeliveryTime = true;
          this.error = "Доставка в другой город сейчас недоступна.";
        } else {
          if (error.message) {
            this.error = error.message;
          } else {
            this.error = "Что-то пошло не так. Пожалуйста, попробуйте, еще раз.";
          }
        }
        this.isErrorDialog = true;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
.basket-form {
  padding-bottom: 128px;

  .btn-primary {
    background: rgba(255, 255, 255, .85) !important;
    //border: none;
  }
}
</style>